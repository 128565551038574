<template>
  

<div>

 <b-modal  id="lolmodal" title="Infos Salaire"  size="lg" hide-footer>






           <form @submit.prevent="handleSubmitLot()"> 



            <div class="row">
                    <div class="form-group col">
                        <label for="salaire_base">Salaire De Base :</label>
                        <input type="text" v-model="salaireInfosR.salaire_base" id="salaire_base" name="salaire_base" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.salaire_base.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.salaire_base.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.salaire_base.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>

                    <div class="form-group col">
                        <label for="prix_heur_supp">Prix Heure Supp (DH) :</label>
                        <input type="text" v-model="salaireInfosR.prix_heur_supp" id="prix_heur_supp" name="prix_heur_supp" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.prix_heur_supp.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.prix_heur_supp.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.prix_heur_supp.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>
            </div>


            <div class="row">
                    <div class="form-group col">
                        <label for="taux_ancien">Taux Ancien (%)</label>
                        <input type="text" v-model="salaireInfosR.taux_ancien" id="taux_ancien" name="taux_ancien" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.taux_ancien.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_ancien.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_ancien.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>

                    <div class="form-group col">
                        <label for="taux_cnss">Taux CNSS (%)</label>
                        <input type="text" v-model="salaireInfosR.taux_cnss" id="taux_cnss" name="taux_cnss" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.taux_cnss.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_cnss.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_cnss.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>
            </div>


            <div class="row">
                    <div class="form-group col">
                        <label for="taux_amo">Taux AMO (%)</label>
                        <input type="text" v-model="salaireInfosR.taux_amo" id="taux_amo" name="taux_amo" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.taux_amo.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_amo.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_amo.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>

                    <div class="form-group col">
                        <label for="taux_cimr">Taux CIMR (%)</label>
                        <input type="text" v-model="salaireInfosR.taux_cimr" id="taux_cimr" name="taux_cimr" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.taux_cimr.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_cimr.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_cimr.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>
            </div>




            <div class="row">
                    <div class="form-group col">
                        <label for="frais_profes">Frais Professionnels (%)</label>
                        <input type="text" v-model="salaireInfosR.frais_profes" id="frais_profes" name="frais_profes" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.frais_profes.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.frais_profes.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.frais_profes.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>

                    <div class="form-group col">
                        <label for="change_fami">Charge Familiale</label>
                        <input type="text" v-model="salaireInfosR.change_fami" id="change_fami" name="change_fami" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.change_fami.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.change_fami.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.change_fami.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>
            </div>

            

            <div class="row">
                    <div class="form-group col">
                        <label for="taux_impos">Taux Impos (%)</label>
                        <input type="text" v-model="salaireInfosR.taux_impos" id="taux_impos" name="taux_impos" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.taux_impos.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_impos.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.taux_impos.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>

                    <div class="form-group col">
                        <label for="deduction_impos">Deduction Impos</label>
                        <input type="text" v-model="salaireInfosR.deduction_impos" id="deduction_impos" name="deduction_impos" class="form-control"
                            :class="{ 'is-invalid': isSubmitted && $v.salaireInfosR.deduction_impos.$error }" />
                        <div v-if="isSubmitted && !$v.salaireInfosR.deduction_impos.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.salaireInfosR.deduction_impos.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Modifier</button>
            </div>

        </form>






</b-modal>


    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";


export default {
 props: ['salaireInfosR'],

      computed: {



  },
	data () {
		return {


            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            salaireInfosR: {
                salaire_base: {
                    required,
                    decimal
                },
                prix_heur_supp: {
                    required,
                    decimal
                },
                taux_ancien: {
                    required,
                    decimal
                },
                taux_cnss: {
                    required,
                    decimal
                },
                taux_amo: {
                    required,
                    decimal
                },
                taux_cimr: {
                    required,
                    decimal
                },
                frais_profes: {
                    required,
                    decimal
                },
                taux_impos: {
                    required,
                    decimal
                },
                deduction_impos: {
                    required,
                    decimal
                },
                change_fami: {
                    required,
                    decimal
                },
            }

    },
    methods :{



              handleSubmitLot() {


                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {





            this.isLoading=true;

            this.$http.patch('/salaireInfos/'+this.salaireInfosR.id,{
                   
                    salaire_base:this.salaireInfosR.salaire_base,
                    prix_heur_supp:this.salaireInfosR.prix_heur_supp,
                    taux_ancien:this.salaireInfosR.taux_ancien,
                    taux_cnss:this.salaireInfosR.taux_cnss,
                    taux_amo:this.salaireInfosR.taux_amo,
                    taux_cimr:this.salaireInfosR.taux_cimr,

                    frais_profes:this.salaireInfosR.frais_profes,
                    taux_impos:this.salaireInfosR.taux_impos,
                    deduction_impos:this.salaireInfosR.deduction_impos,
                    change_fami:this.salaireInfosR.change_fami,
                    rh_empl_id:this.salaireInfosR.rh_empl_id,


                })
                .then(response => {

                this.isLoading=false;


                console.log(response)

                this.$bvModal.hide('lolmodal')


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

                }

            },

        
    }

    ,
    components: {
              Loading,
             
    }
}
</script>

<style>

</style>