<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                                <label for="pointeuse_id" class="mr-sm-2 pt-2"> Pointeuse :</label>

                                            <select class="form-control border rounded mr-4" id="pointeuse_id" v-model="itemFormBon.pointeuse_id">

                                            <option v-for="item in pointeuses" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            <option  value=0>Tous</option>

                                            </select>
        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> Date : </label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        



                                                            <div class="input-group-prepend">
                                                                <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                                                <span class="fa fa-search"></span> Liste
                                                                </button>
                                                            </div>

                                
                                                            <b-button pill   class="m-2 float-right" variant="success" @click="add_click($event.target)">
                                                                        <i class="fa fa-plus-square"></i>
                                                                <span> Ajouter</span> 
                                                            </b-button>
                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Feuille Du Temps</h4> 

                    <h5 class="font-weight-light col ">Total : {{total_travail}} </h5>

                    <div class="col">


                                    <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       



        <b-table 
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"

        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >

            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                    </b-button>
            </template>

        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>



    <!-- le modal -->
    <b-modal id="banqModal" title="Pointage" size="lg"  hide-footer>
           <form @submit.prevent="handleSubmit">
               <div class="row ">
                <div class="form-group col">
                    <label for="date" class="mr-sm-2">Date :</label>
                        <b-form-datepicker
                        class="mr-sm-2 form-control  form-control-sm"
                        id="datepicker-date"
                        placeholder="date"
                        v-model="itemPointForm.date"
                        :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                        locale="fr" />
                </div>

                <div class="form-group col">
                                            <label for="rh_empl_id">Employé :</label>
                                            <select class="form-control" id="rh_empl_id" v-model="itemPointForm.rh_empl_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemPointForm.rh_empl_id.$error }">
                                            <option v-for="item in employeesList" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom_prenom }}
                                            </option>
                                            </select>

                                <div v-if="isSubmitted && !$v.itemPointForm.rh_empl_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                       
                </div>
</div>

            <div class="row ">
                <div class="form-group col">
                    <label for="date" class="mr-sm-2">Heures D'Entrée :</label>
                    
                    <b-form-timepicker
                        id="ex-heur_debut"
                        v-model="itemPointForm.heur_debut"
                    ></b-form-timepicker>

                </div>

                <div class="form-group col">
                    <label for="date" class="mr-sm-2">Heures De Sortie :</label>
                    
                    <b-form-timepicker
                        id="ex-heur_sotrie"
                        v-model="itemPointForm.heur_sotrie"
                    ></b-form-timepicker>

                </div>

            </div>


            <div class="row ">


                <div class="form-group col">
                    <label for="name">Heures Travaillées :</label>
                    <input type="text" v-model="itemPointForm.h_travail" id="name" name="name" class="form-control"
                        :class="{ 'is-invalid': isSubmitted && $v.itemPointForm.h_travail.$error }" />
                    <div v-if="isSubmitted && !$v.itemPointForm.h_travail.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemPointForm.h_travail.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>

                </div>

                
                <div class="form-group col">
                    <label for="name">Minutes Travaillées :</label>
                    <input type="text" v-model="itemPointForm.m_travail" id="name" name="name" class="form-control"
                        :class="{ 'is-invalid': isSubmitted && $v.itemPointForm.m_travail.$error }" />
                    <div v-if="isSubmitted && !$v.itemPointForm.m_travail.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemPointForm.m_travail.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>

                </div>

            </div>



            <div class="row ">


                
                <div class="form-group col">
                                            <label for="zone_id">Zone :</label>
                                            <select class="form-control" id="zone_id" v-model="itemPointForm.zone_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemPointForm.zone_id.$error }">
                                            <option v-for="item in zones" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>
                                            </select>

                                <div v-if="isSubmitted && !$v.itemPointForm.zone_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                       
                </div>



                <div class="form-group col">
                                <label for="pointeuse_idid"> Pointeuse :</label>

                                            <select class="form-control" id="pointeuse_idid" v-model="itemPointForm.pointeuse_id"
                                            :class="{ 'is-invalid': isSubmitted && $v.itemPointForm.pointeuse_id.$error }">

                                            <option v-for="item in pointeuses" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            </select>

                                <div v-if="isSubmitted && !$v.itemPointForm.pointeuse_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                       
                </div>
                

            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>


        </form>
    </b-modal>




    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {

      computed: {
          
      rows_pagin() {
        return this.itemslist.length
      },

      total_travail: function() {
        let heur =  this.itemslist.reduce((a, b) => +a + +b.h_travail, 0);
        let min = this.itemslist.reduce((a, b) => +a + +b.m_travail, 0);

        var hours = (min / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        rhours = heur+ rhours;



        return rhours + " H "+rminutes +"Min" ;
    },

    },

    mounted(){
  this.fetchCentre();
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    pointeuse_id:'',
                    centre_nom:''
            },
            itemPointForm: {
                        date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                        heur_debut:'',
                        heur_sotrie:'',
                        h_travail:'',
                        m_travail:'',

                        zone_id:'',
                        pointeuse_id:'',
                        rh_empl_id:''
            },

            employeesList:[],
            zones:[],
            pointeuses:[],
            itemslist: [],
            fields: [
                {key: 'nom_prenom', label: 'Nom Et Prénom', sortable: true},
				{key: 'date', label: 'Date', sortable: true},
                {key: 'heur_debut', label: 'H.Entrée',
                formatter: (value) => {
                 return  new Date(value).getHours() + ":"+new Date(value).getMinutes()
                }, sortable: true},
				{key: 'heur_sotrie', label: 'H.Sortie',
                formatter: (value) => {
                 return  new Date(value).getHours() + ":"+new Date(value).getMinutes()
                },sortable: true},

                {key: 'h_travail', label: 'Heures', sortable: true},
				{key: 'm_travail', label: 'Minutes', sortable: true},

                {key: 'zone', label: 'Zone', sortable: true},
                {key: 'pointeuse', label: 'Pointeuse', sortable: true},
                { key: 'actions', label: '' }

            ],

            isSubmitted: false,
            isLoading: false
		}

	},
    validations: {
            itemPointForm: {
                heur_debut: {
                    required
                },
                heur_sotrie: {
                    required
                },
                h_travail: {
                    required,
                    decimal
                },
                m_travail: {
                    required,
                    decimal
                },
                zone_id: {
                    required
                },
                pointeuse_id: {
                    required
                },
                rh_empl_id: {
                    required
                }
            }

    },
    methods :{

   exportDox()
                        {

                        let cent = ""


                        if(this.itemFormBon.pointeuse_id==0)
                        {
                        cent="Tous"
                        }
                        else
                        {
                        let mm=this.pointeuses.findIndex(item => item.id === this.itemFormBon.pointeuse_id)
                        cent= this.pointeuses[mm].nom
                        }



                        let datrw =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Nom Et Prénom",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 1000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "H.Entrée",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        

                                        new TableCell({
                                            width: {
                                                size: 1000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "H.Sortie",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),



                                        new TableCell({
                                            width: {
                                                size: 1000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Heures Travail",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        
                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Zone",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        
                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Pointeuse",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                    ],
                                })]

                        this.itemslist.forEach(function(item){

                        let r = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.nom_prenom,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 1000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: new Date(item.heur_debut).getHours() + ":"+new Date(item.heur_debut).getMinutes(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 1000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: new Date(item.heur_sotrie).getHours() + ":"+new Date(item.heur_sotrie).getMinutes(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        


                                        new TableCell({
                                            width: {
                                                size: 1500,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.h_travail.toString() + " h"+item.m_travail.toString()+"min",
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        

                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.zone,
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        

                                        new TableCell({
                                            width: {
                                                size: 2000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.pointeuse,
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                    ],
                                })

                        datrw.push(
                        r
                        )
                        });

                                const doc = new Document();

                        const table = new Table({

                            rows:  datrw
                        })



                                doc.addSection({


                                    properties: {
                                        page: {
                                            pageNumbers: {
                                                start: 1,
                                                formatType: PageNumberFormat.DECIMAL,
                                            },
                                        },
                                    },
                                    headers: {
                                        default: new Header({
                                            children: [
                                                new Paragraph({
                                                    children: [
                                            new TextRun({
                                                text: "تعاونية حليب الريش",
                                                color: '636572',
                                                bold: true,

                                                })
                                                    ],
                                                border: {
                                                bottom: {
                                                        color: "636572",
                                                        space: 4,
                                                        value: "single",
                                                        size: 10,
                                                    }},
                                                    heading: HeadingLevel.TITLE,
                                                    alignment: AlignmentType.CENTER,
                                                }),
                                            ],
                                        }),
                                    },
                                    footers: {
                                        default: new Footer({
                                            children: [
                                                new Paragraph({
                                                    alignment: AlignmentType.RIGHT,
                                                    children: [
                                                        new TextRun({
                                                            children: ["Page : ", PageNumber.CURRENT],
                                                        }),
                                                        new TextRun({
                                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                        }),
                                                    ],
                                                }),
                                            ],
                                        }),
                                    },



                                    children: [

                                        new Paragraph({



                                            children: [

                                                new TextRun({
                                                text: "Feuille Du Temps",
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })
                                            ],

                                            heading: HeadingLevel.HEADING_2,
                                                                alignment: AlignmentType.CENTER,

                                                spacing: {
                                                    after: 300,
                                                    before: 300
                                                },
                                        }),
                                        

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "     Date : "+this.itemFormBon.dateDebut,
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                                                new TextRun({
                                                text: "       Poiteuse : "+cent,
                                                color: '636572',
                                                bold: false,
                                                font: "Segoe UI Light"
                                            })

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),


                                        table
                                        
                                        ]
                                })







                            Packer.toBlob(doc).then(blob => {

                        saveAs(blob, "feuille_temps.docx");

                            });

    },

        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                        this.banqueDell(item.id)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        banqueDell(idp){

            this.isLoading=true;

            this.$http.delete('/pointTime/'+idp)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === idp)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        handleSubmit() {


                        this.isSubmitted = true;

                        this.$v.$touch();
                        if (this.$v.$invalid) {
                        this.flashMessage.show({
                        status: 'info',
                        title: 'Vérifiez les données et Reésayez !!',
                        })
                        }
                        else 
                        {

                        // alert(new Date(this.itemPointForm.date +" "+this.itemPointForm.heur_debut))
                        this.itemslisttore()
                        }



        },

        itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/pointTime',{
            date:this.itemPointForm.date,
            heur_debut:this.itemPointForm.date +" "+this.itemPointForm.heur_debut,
            heur_sotrie:this.itemPointForm.date +" "+this.itemPointForm.heur_sotrie,
            h_travail:this.itemPointForm.h_travail,
            m_travail:this.itemPointForm.m_travail,
            zone_id:this.itemPointForm.zone_id,
            pointeuse_id:this.itemPointForm.pointeuse_id,
            rh_empl_id:this.itemPointForm.rh_empl_id
                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {

            let today = new Date();
            this.itemPointForm.heur_debut = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            this.itemPointForm.heur_sotrie = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

//initialiser les champs:
            this.itemPointForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            this.itemPointForm.h_travail='',
            this.itemPointForm.m_travail='',
            this.itemPointForm.zone_id='',
            this.itemPointForm.pointeuse_id='',
            this.itemPointForm.rh_empl_id=''


                        //chager les zones:
                        this.isLoading=true;
                        this.$http.get('/zones')
                        .then((result) => {
                                    this.zones = result.data.data
                                    this.isLoading=false;
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });




                        //chager les zones:
                        this.isLoading=true;
                        this.$http.get('/empoyeListMin/0')
                        .then((result) => {
                                    this.employeesList = result.data.data
                                    this.isLoading=false;
                                    this.$root.$emit('bv::show::modal', 'banqModal', button)
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });



        },
                fetchCentre: function () {


                    //chager les pointeuses:
                    this.isLoading=true;
                    this.$http.get('/pointeuses')
                    .then((result) => {
                                this.pointeuses = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
                handleSubmitBonCMD(){




                if (this.itemFormBon.pointeuse_id && this.itemFormBon.dateDebut)
                {
                this.fetchList(this.itemFormBon.pointeuse_id)
                }
                else 
                {

                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })


                }


        },

        fetchList: function (idpoint) {
                    this.isLoading=true;
                    
                    this.$http.get('/pointTimeDat/'+this.itemFormBon.dateDebut+'/'+idpoint)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>