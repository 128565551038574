<template>
  
<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">
        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-address-book"></span> Traitement Des Salaires</h4> 
            
        </div>

<div class="row h-100 m-2">
  
        <div class="col-4">

               <div class="card border-info h-100 overflow-auto">


        <form @submit.prevent="SubmitFtchClient()" class="bg-info">

                <div class="input-group  border border-info rounded bg-info p-1 col">
                                              <div class="input-group-prepend">
                                                <span class="input-group-text">Départ.. </span>
                                              </div>
                                            
                                            <select  class="form-control" id="rh_departement_id" v-model="itemEmploForm.rh_departement_id">
                                            <option v-for="item in departements" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            </select>

                </div>


                <div class="input-group  border border-info rounded bg-info p-1 col">
                                                            <select class="form-control border border-info BgSearch " id="mois" v-model="itemEmploForm.mois">
                                                            <option value=1>Janvier</option>
                                                            <option value=2>Février</option>
                                                            <option value=3>Mars</option>
                                                            <option value=4>Avril</option>
                                                            <option value=5>Mai</option>
                                                            <option value=6>Juin</option>
                                                            <option value=7>Juillet</option>
                                                            <option value=8>Août</option>
                                                            <option value=9>Septembre</option>
                                                            <option value=10>Octobre</option>
                                                            <option value=11>Novembre</option>
                                                            <option value=12>Décembre</option>
                                                            </select>
                </div>



                <div class="border border-info rounded col p-1">
                        <button class="btn m-0 p-1 BgSearch btn-light btn-block border border-info rounded" type="submit" id="button-addon1">   
                          <span class="fa fa-search"></span> Liste
                        </button>
                </div>
                                                 

                                   
        </form>



                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchclient"  class="border-info " id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCINBarreSearch"
                                                v-model="isCINBarreSearch"
                                                name="isCINBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCINBarreSearch">Recherche Par Code</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                         
                            <b-list-group-item  v-for="item in filterEmployes" :key="item.id" button class="p-3" variant="info" @click="employe_click(item)">
                                 {{ item.nom_prenom }} -  {{ item.code }} 

                            </b-list-group-item>                          

                            </b-list-group>
               </div>
        </div> 


            <!-- LA PARTIE DES CARDES -->
        <div v-if="itemEmploForm.employe_id"  class="col-8  mr-auto ">


          <div class="row card shadow mb-3 p-1">



                <div class="row m-0 text-secondary">
                        <div class=" col">

                                        <h5 class="float-left font-weight-normal ml-3 pt-1">{{itemEmploForm.employe_nom}} - {{itemEmploForm.mois_nom}}</h5>

                                            

                                        <b-button v-if="is_valider==true" pill size="sm"  class="m-1 float-right" variant="info" @click="exportDox">
                                                    <i class="fa fa-print"></i>
                                            <span> Imprimer</span> 
                                        </b-button>

                                        <b-button  v-if="is_valider==true" pill size="sm"  class="m-1 float-right" variant="secondary" @click="click_annulSalaire">
                                                    <i class="fa fa-eraser"></i>
                                            <span> Annuler</span> 
                                        </b-button>

                        </div>
                </div>
            </div>



          <div v-if="is_valider==false" class="row  card shadow mb-0 p-1">

            <div class="row m-0">
                <div class="col m-0">
                <h6 class="font-weight-lighter text-secondary"> <span class="text-info">Heures Travaillés : </span> {{itemHeurMin.heurs}} h {{itemHeurMin.mins}} min</h6> 
                                <h6 v-if="itemEmploForm.vendeur_id !=0" class="font-weight-lighter text-secondary"> <span class="text-info">Chiffre D'affaire : </span> {{itemHeurMin.chiffr}} DH</h6> 
                </div>
                <div class="col m-0">

                <h6 class="font-weight-lighter text-secondary"> <span class="text-info">Absences Non Justifiés : </span> {{itemHeurMin.absencs}} Jour</h6> 
                                <h6 v-if="itemEmploForm.vendeur_id !=0" class="font-weight-lighter text-secondary"> <span class="text-info">Crédits : </span> {{itemHeurMin.cred}} DH</h6> 
                
                </div>
            </div>


            <div class="row m-0">
            <div class="form-group col">
                <label for="par_heur_suppNmbr">Heures Supp :</label>
                <input type="text" v-model="parametreSalaire.par_heur_suppNmbr" id="par_heur_suppNmbr" name="par_heur_suppNmbr" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.parametreSalaire.par_heur_suppNmbr.$error }" />
                <div v-if="isSubmitted && !$v.parametreSalaire.par_heur_suppNmbr.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">
                <label for="retenus_prets">Retenue Prêt :</label>
                <input type="text" v-model="parametreSalaire.retenus_prets" id="retenus_prets" name="retenus_prets" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.parametreSalaire.retenus_prets.$error }" />
                <div v-if="isSubmitted && !$v.parametreSalaire.retenus_prets.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            </div>


                
            <div class="row m-0">
            <div class="form-group col m-0">
                <label for="indeminit_impos">Indemnités imposables :</label>
                <input type="text" v-model="parametreSalaire.indeminit_impos" id="indeminit_impos" name="indeminit_impos" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.parametreSalaire.indeminit_impos.$error }" />
                <div v-if="isSubmitted && !$v.parametreSalaire.indeminit_impos.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col m-0">
                <label for="indeminit_non_impos">Indemnités non imposables :</label>
                <input type="text" v-model="parametreSalaire.indeminit_non_impos" id="indeminit_non_impos" name="indeminit_non_impos" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.parametreSalaire.indeminit_non_impos.$error }" />
                <div v-if="isSubmitted && !$v.parametreSalaire.indeminit_non_impos.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            </div>


            <div class="form-group col">
            <label for="Description">Description</label>
            <textarea name="description" id="description"  rows="2" class="form-control" v-model="parametreSalaire.description"></textarea>

            </div>

            <div class="row m-3">
                <b-button v-if="is_valider==false" pill size="sm"  class="m-0 btn-block" variant="outline-success" @click="calculer_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Calculer</span> 
                </b-button>

            </div>

          </div>


          <div class="row mb-3 card shadow">

              <div class="row m-0">
                <div class="col">
                    <h6 class="font-weight-normal ml-2 mt-4"> Salaire De Base : {{itemsalaireinfos.salaire_base}}</h6>
                </div>
                <div class="col">
                    <h6 class="font-weight-normal ml-2 mt-4"> Heures Supp ( {{itemsalaireinfos.prix_heur_supp}} DH/h ) : {{parametreSalaire.supp_heur_DH}} DH</h6>
                </div>
            </div>

          </div>
        

          <div class="row card shadow mb-3">

                <div class="row m-0">
                            <div class="col">
                                <h6 class="font-weight-normal ml-2 mt-4"> Prime d'ancienneté ( {{itemsalaireinfos.taux_ancien}} % ) : {{parametreSalaire.prim_anciente}}</h6>
                            </div>

                            <div class="col">
                                <h6 class="font-weight-lighter ml-2 mt-4 text-secondary"> Recrutement : {{itemEmploForm.date_recrut}}</h6>
                            </div>
                </div>

                <div class="row m-0">
                            <div class="col">
                                <h6 class="font-weight-normal ml-2 mt-4"> Indemnités imposables : {{parametreSalaire.indeminit_impos}}</h6>
                            </div>
                            <div class="col">
                                <h6 class="font-weight-normal ml-2 mt-4"> Indemnités non imposables : {{parametreSalaire.indeminit_non_impos}}</h6>
                            </div>
                </div>

           

          </div>



          <div class="row  card shadow mb-3">

              <div class="row m-0">
                <div class="col">
                    <h6 class="font-weight-normal ml-2 mt-2"> Salaire Brut : {{parametreSalaire.salair_brut_impo}}</h6>
                </div>

            </div>

          </div>
        


          <div class="row card shadow mb-3">


                <div class="row m-0">
                            <div class="col">
                                <h6 class="font-weight-normal ml-2 mt-1"> CNSS ( {{itemsalaireinfos.taux_cnss}} %)  : {{parametreSalaire.par_cnss}}</h6>
                            </div>

                            <div class="col">
                                <h6 class="font-weight-normal ml-2 mt-1"> AMO ( {{itemsalaireinfos.taux_amo}} %)  : {{parametreSalaire.par_amo}}</h6>
                            </div>
                </div>

                <div class="row m-0">
                            <div class="col">
                                <h6 class="font-weight-normal ml-2 mt-1"> CIMR ( {{itemsalaireinfos.taux_cimr}} %)  : {{parametreSalaire.par_cimr}}</h6>
                            </div>
                            <div class="col">
                                <h6 class="font-weight-normal ml-2 mt-1"> Frais Professionnels ( {{itemsalaireinfos.frais_profes}} %)  : {{parametreSalaire.par_frais}}</h6>
                            </div>
                </div>

          

          </div>


          <div class="row card shadow mb-3">

              <div class="row m-0">
                <div class="col">
                    <h6 class="font-weight-normal ml-2 pt-1"> Salaire Net Imposable : {{parametreSalaire.salair_net_impo}}</h6>
                </div>

            </div>

          </div>


          <div class="row card shadow mb-3">

              <div class="row m-0">
                <div class="col">
                    <h6 class="font-weight-normal ml-2 pt-1"> Impôt Sur Salaire (IR) ( {{itemsalaireinfos.taux_impos}} %) : {{parametreSalaire.ir_brut}}</h6>
                </div>

            </div>

          </div>


          <div class="row card shadow mb-3">

              <div class="row m-0">
                <div class="col">
                    <h6 class="font-weight-normal ml-2 pt-1"> Charge Familiale ( {{itemsalaireinfos.change_fami}} Personnes ) : {{parametreSalaire.par_change_fami}}</h6>
                </div>

            </div>

          </div>


          <div class="row card shadow mb-3">

              <div class="row m-0">
                <div class="col">
                    <h6 class="font-weight-normal ml-2 pt-1"> Retenue Prêt : {{parametreSalaire.retenus_prets}}</h6>
                </div>

            </div>

          </div>



          <div class="row card shadow mb-3">

              <div class="row m-0">
                <div class="col">
                    <h5 class="font-weight-normal ml-2 text-info pt-1"> Salaire Net : {{parametreSalaire.salaire_net}}</h5>
                </div>

            </div>

          </div>





            <div class="row mb-4">
                <b-button v-if="is_valider==false" pill size="sm"  class="p-1 m-0 btn-block" variant="success" @click="valider_click">
                            <i class="fa fa-check"></i>
                    <span> Valider</span> 
                </b-button>

            </div>

        </div>   


</div> 


    <!-- le modal -->
    <b-modal id="comptModal" title="Sélectionnez un compte"  hide-footer>
            <form @submit.prevent="handleSubmitCmp">
                            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="parametreSalaire.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>


            <div class="form-group">
                <button  type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>
            </form>  

    </b-modal>


      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>



</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {
      user_name() {
      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
       filterEmployes() {
       return this.employeList.filter(clnt => {

            if(this.isCINBarreSearch)
            {
                if(clnt.code)
                {
                return clnt.code.toLowerCase().includes(this.serchclient.toLowerCase())
                }

            }
            else
            {

                if(clnt.nom_prenom)
                {
                return clnt.nom_prenom.toLowerCase().includes(this.serchclient.toLowerCase())
                }


            }

      }) 
    }
    },
    mounted(){
      this.fetchdepartements() 
    },
    data () {
		return {
            isAnnulSalaire: false,
            is_valider: false,
            perPage: 10,
            currentPage: 1,

            departements:[],
            comptes:[],
            itemEmploForm: {
                        rh_departement_id:'',
                        mois: (new Date().getMonth())+1,
                        years: (new Date().getFullYear()),
                        mois_nom:'',
                        employe_id:'',
                        employe_nom:'',
                        date_recrut:'',
                        civilite:'',
                        vendeur_id:0,
            },
            itemHeurMin: {
                        heurs:0,
                        mins:0,
                        absencs:0,
                        chiffr:0,
                        cred:0
            },
            employeList:[],


            isSubmitted: false,
            isLoading: false,
            isCINBarreSearch: false,
            serchclient:'',

            //salaires traitement:
            itemsalaireinfos: {
                        salaire_base:'',
                        prix_heur_supp:'',
                        taux_ancien:'',
                        taux_cnss:'',
                        taux_amo:'',

                        taux_cimr:'',
                        frais_profes:'',
                        taux_impos:'',
                        deduction_impos:'',
                        change_fami:'',
            },

            //salaires traitement: 
            parametreSalaire: {
                        id:'',
                        par_heur_suppNmbr:0,
                        supp_heur_DH:0,
                        prim_anciente:0,

                        indeminit_impos:0,
                        indeminit_non_impos:0,


                        salair_brut_impo:0,

                        base_cnss:0,

                        par_cnss:0,
                        par_amo:0,
                        par_cimr:0,
                        par_frais:0,

                        salair_net_impo:0,

                        ir_brut:0,

                        par_change_fami:0,

                        retenus_prets:0,

                        salaire_net:0,

                        description:'',
                        bank_compte_id:0
            },

        }
        
        },
   validations: {
            parametreSalaire: {
                indeminit_non_impos: {
                    required
                },
                indeminit_impos: {
                    required
                },
                par_heur_suppNmbr: {
                    required
                },
                retenus_prets: {
                    required
                },


            }

    },
        methods:{ 


exportDox()
        {

    /*

        if(this.itemFormBon.depot_id==0)
        {
        cent="Tous"
        }
        else
        {
        let mm=this.depots.findIndex(item => item.id === this.itemFormBon.depot_id)
        cent= this.depots[mm].nom
        }*/

        let datrw_entete =[
            
            new TableRow({
            children: [
                       
                        new TableCell({
                            width: {
                                size: 5250,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Employé : "+this.itemEmploForm.employe_nom,
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 5250,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:  "Code / CIN : "+this.itemEmploForm.code,
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })
              ,
            new TableRow({
            children: [
                       
                        new TableCell({
                            width: {
                                size: 5250,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Mois : "+this.itemEmploForm.mois.toString() + "/"+this.itemEmploForm.years.toString(),
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 5250,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:  "Date d'embauche : "+this.itemEmploForm.date_recrut,
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })  
                
                
                
                
                ]




        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Rubrique",
                                            spacing: {
                                            before: 100,
                                            after: 100
                                        },
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Nombre / Taux",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Base",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "A Payer",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "A Retenir",
                                        heading: HeadingLevel.HEADING_4,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })]

        let r_salai_base = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Salaire de base",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.itemsalaireinfos.salaire_base.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        let r_ancient = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Prime d'ancienneté",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:this.itemsalaireinfos.taux_ancien.toString()+ " %",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.prim_anciente.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        let r_indim_impo = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Indemnités imposables",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:"",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.indeminit_impos.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        let r_indim_non_impo = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Indemnités non imposables",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:"",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.indeminit_non_impos.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })
        let r_salair_brut = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Salaire Brut",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:"",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.salair_brut_impo.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        let r_frais_prof = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Frais Professionnels",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:this.itemsalaireinfos.frais_profes.toString() +" %",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.par_frais.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        let r_cnss = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Cotisation CNSS",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:this.itemsalaireinfos.taux_cnss.toString() +" %",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.base_cnss.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.par_cnss.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        let r_amo = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Cotisation AMO",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:this.itemsalaireinfos.taux_amo.toString() +" %",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.salair_brut_impo.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.par_amo.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })
        let r_cimr = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Cotisation CIMR",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:this.itemsalaireinfos.taux_cimr.toString() +" %",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.salair_brut_impo.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.par_cimr.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })
        
        let r_salair_net_impo = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Salaire Net Imposable",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:"",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.salair_net_impo.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })
          
        let r_ir = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Impôt Sur Salaire",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:this.itemsalaireinfos.taux_impos.toString() +" %",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: this.parametreSalaire.salair_net_impo.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:  this.parametreSalaire.ir_brut.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

          
        let r_charge = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Charge Familiale",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:this.itemsalaireinfos.change_fami.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "30.00",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:  this.parametreSalaire.par_change_fami.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })
          
        let r_retenu_pret = new TableRow({
                    children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Retenue Prêt",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:"",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "",
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: '',
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:  this.parametreSalaire.retenus_prets.toString(),
                                        heading: HeadingLevel.HEADING_6,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })



        let datrw_salare_net =[new TableRow({
            children: [
                       
                        new TableCell({
                            width: {
                                size: 3000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text: "Salaire Net",
                                            spacing: {
                                            before: 80,
                                            after: 80
                                        },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 7500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        text:  this.parametreSalaire.salaire_net.toString() + " DH",
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })]



        datrw.push(r_salai_base)
        datrw.push(r_ancient)
        datrw.push(r_indim_impo)
        datrw.push(r_indim_non_impo)
        datrw.push(r_salair_brut)
        datrw.push(r_frais_prof)     
        datrw.push(r_cnss)
        datrw.push(r_amo)
        datrw.push(r_cimr)
        datrw.push(r_salair_net_impo)
        datrw.push(r_ir)
        
        datrw.push(r_charge)

        datrw.push(r_retenu_pret)

        const doc = new Document();

        const table_entet = new Table({

            rows:  datrw_entete
        })

        const table = new Table({

            rows:  datrw
        })

        
        const tablenet = new Table({

            rows:  datrw_salare_net
        })




                doc.addSection({


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                                new Paragraph({
                                    children: [
                            new TextRun({
                                text: "تعاونية حليب الريش",
                                color: '636572',
                                bold: true,

                                })
                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),
                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [
                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "BULLETIN DE PAIE",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),
                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),

                        table_entet,
                        

                        new Paragraph({
                            children: [

                                new TextRun({
                                text: "",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 50,
                                    before: 50
                                },
                        }),


                        table,

                        new Paragraph({
                            children: [

                                new TextRun({
                                text: "",
                                color: '636572',
                                bold: false,
                                font: "Segoe UI Light"
                            }),

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 50,
                                    before: 50
                                },
                        }),

                        tablenet
                        
                        ]
                })







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Bul_Salaire_"+this.itemEmploForm.employe_nom+".docx");

            });

        },








        calculer_click()
        {
            this.parametreSalaire.supp_heur_DH = (Number( this.itemsalaireinfos.prix_heur_supp) * Number(this.parametreSalaire.par_heur_suppNmbr)).toFixed(2)

            this.parametreSalaire.prim_anciente = ((Number( this.itemsalaireinfos.salaire_base) + Number(this.parametreSalaire.supp_heur_DH))*(Number(this.itemsalaireinfos.taux_ancien) * 0.01)).toFixed(2)

            this.parametreSalaire.salair_brut_impo = Number( this.itemsalaireinfos.salaire_base) + Number(this.parametreSalaire.supp_heur_DH) +Number( this.parametreSalaire.prim_anciente) + Number(this.parametreSalaire.indeminit_impos)


                if(this.parametreSalaire.salair_brut_impo>6000 )
                {
                this.parametreSalaire.base_cnss = 6000
                }
                else
                {
                this.parametreSalaire.base_cnss = this.parametreSalaire.salair_brut_impo
                }


            //deduction :
            this.parametreSalaire.par_cnss = (Number(this.parametreSalaire.base_cnss)*(Number(this.itemsalaireinfos.taux_cnss) * 0.01)).toFixed(2)
            this.parametreSalaire.par_amo = (Number(this.parametreSalaire.salair_brut_impo)*(Number(this.itemsalaireinfos.taux_amo) * 0.01)).toFixed(2)
            this.parametreSalaire.par_cimr = (Number(this.parametreSalaire.salair_brut_impo)*(Number(this.itemsalaireinfos.taux_cimr) * 0.01)).toFixed(2)
            this.parametreSalaire.par_frais = (Number(this.parametreSalaire.salair_brut_impo)*(Number(this.itemsalaireinfos.frais_profes) * 0.01)).toFixed(2)
            //net imposable :

            this.parametreSalaire.salair_net_impo = (Number(this.parametreSalaire.salair_brut_impo) -Number(this.parametreSalaire.par_frais) - Number(this.parametreSalaire.par_cnss) -Number( this.parametreSalaire.par_amo) - Number(this.parametreSalaire.par_cimr)).toFixed(2)
            this.parametreSalaire.ir_brut = (((Number(this.parametreSalaire.salair_net_impo))* (Number(this.itemsalaireinfos.taux_impos) * 0.01))- Number(this.itemsalaireinfos.deduction_impos)).toFixed(2)
            this.parametreSalaire.par_change_fami = Number(this.itemsalaireinfos.change_fami) * 30


            this.parametreSalaire.salaire_net = (Number(this.parametreSalaire.salair_net_impo)+ Number(this.parametreSalaire.par_frais)+ Number(this.parametreSalaire.indeminit_non_impos) + Number(this.parametreSalaire.par_change_fami) - Number(this.parametreSalaire.ir_brut) - Number(this.parametreSalaire.retenus_prets)).toFixed(2)


        },
        fetchdepartements: function () {

                    //chager les departements:
                    this.isLoading=true;
                    this.$http.get('/departements')
                    .then((result) => {
                                this.departements = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        SubmitFtchClient(){

                if (this.itemEmploForm.rh_departement_id && this.itemEmploForm.mois) {
                this.fetchListEmployes(this.itemEmploForm.rh_departement_id)
                }
                else 
                {

                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })


                }


        },
        fetchListEmployes(id_depart) {
                    this.isLoading=true;
                    this.$http.get('/empoyeListMin/'+id_depart)
                    .then((result) => {
                                this.employeList = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        employe_click(itm)
        {



            this.itemEmploForm.employe_id=itm.id

            this.itemEmploForm.employe_nom=itm.nom_prenom
            this.itemEmploForm.code=itm.code
            this.itemEmploForm.date_recrut=itm.date_recrut
            this.itemEmploForm.civilite=itm.civilite
            this.itemEmploForm.vendeur_id=itm.vendeur_id

            

            this.getMoisName(this.itemEmploForm.mois)

            this.verifier_salaire(itm.id,this.itemEmploForm.mois)

            this.getHeuresTravail(itm.id,this.itemEmploForm.mois,itm.vendeur_id)

        }, 
        fetchSalaireInfos: function (emp_id) {
          this.isLoading = true;

                    this.$http.get('/getSalaireInfos/'+emp_id)
                    .then((result) => {

                                this.isLoading=false;

                                if(result.data.sal !=null)
                                {
                
                                    this.itemsalaireinfos.salaire_base= result.data.sal.salaire_base
                                    this.itemsalaireinfos.prix_heur_supp= result.data.sal.prix_heur_supp
                                    this.itemsalaireinfos.taux_ancien= result.data.sal.taux_ancien
                                    this.itemsalaireinfos.taux_cnss= result.data.sal.taux_cnss
                                    this.itemsalaireinfos.taux_amo= result.data.sal.taux_amo

                                    this.itemsalaireinfos.taux_cimr= result.data.sal.taux_cimr
                                    this.itemsalaireinfos.frais_profes= result.data.sal.frais_profes
                                    this.itemsalaireinfos.taux_impos= result.data.sal.taux_impos
                                    this.itemsalaireinfos.deduction_impos= result.data.sal.deduction_impos
                                    this.itemsalaireinfos.change_fami= result.data.sal.change_fami


                        //tester tau ancien

                        if(this.itemsalaireinfos.taux_ancien==0)
                        {

                        let datenew = new Date();
                        let dateold = new Date(this.itemEmploForm.date_recrut);


                        var ynew = datenew.getFullYear();
                        var mnew = datenew.getMonth();
                        var dnew = datenew.getDate();
                        var yold = dateold.getFullYear();
                        var mold = dateold.getMonth();
                        var dold = dateold.getDate();
                        var diff = ynew - yold;
                        if(mold > mnew) diff--;
                        else
                        {
                            if(mold == mnew)
                            {
                            if(dold > dnew) diff--;
                            }
                        }

               
                                    if(diff <2)
                                    {
                                    this.itemsalaireinfos.taux_ancien =0
                                    }
                                    if(diff >=2 && diff <5)
                                    {
                                    this.itemsalaireinfos.taux_ancien =5
                                    }
                                    else if(diff >=5 && diff <12)
                                    {
                                    this.itemsalaireinfos.taux_ancien =10
                                    }
                                    else if(diff >=12 && diff <20)
                                    {
                                    this.itemsalaireinfos.taux_ancien =15   
                                    }
                                    else if(diff >=20 && diff <25)
                                    {
                                    this.itemsalaireinfos.taux_ancien =20   
                                    }
                                    else if(diff >25)
                                    {
                                    this.itemsalaireinfos.taux_ancien =25 
                                    }
                        }                    

                        //intialiser les autres champ
                                    this.parametreSalaire.supp_heur_DH = 0

                                    this.parametreSalaire.prim_anciente = 0

                                    this.parametreSalaire.salair_brut_impo = 0
                                    //deduction :
                                    this.parametreSalaire.par_cnss = 0
                                    this.parametreSalaire.par_amo =0
                                    this.parametreSalaire.par_cimr =0 
                                    this.parametreSalaire.par_frais =0 
                                    //net imposable :
                                    this.parametreSalaire.salair_net_impo = 0
                                    this.parametreSalaire.ir_brut = 0
                                    this.parametreSalaire.par_change_fami = 0
                                    this.parametreSalaire.salaire_net = 0


                                    this.parametreSalaire.par_heur_suppNmbr= 0
                                    this.parametreSalaire.indeminit_impos= 0
                                    this.parametreSalaire.indeminit_non_impos= 0
                                    this.parametreSalaire.retenus_prets= 0
                                    this.parametreSalaire.description=''


//pa

                                }
                                else
                                {
                                                    this.flashMessage.show({
                                                    status: 'info',
                                                    title: 'Impossible de trouver les infos de salaire !!',
                                                    })


                                                    //cacher la partie :
                                                    this.itemEmploForm.employe_id =''
                                }
                               

                               // 
                    })
                    .catch(err => {
                       this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                            })

                                

                                                    //cacher la partie :
                                                    this.itemEmploForm.employe_id =''
                                
                                console.log(err.message);
        });

        },
        valider_click() {

                                this.$confirm("Voulez-vous valider le salaire ??")
                                .then(() => {

                                this.isAnnulSalaire = false;


                                this.chargr_affich_compt();



                                })
                                .catch(error=> {
                                console.log(error)

                                    });
        },
        chargr_affich_compt()
        {
                    this.parametreSalaire.bank_compte_id=''
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'comptModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmitCmp() {

            if(this.parametreSalaire.bank_compte_id)
            {
                                if(this.isAnnulSalaire==false)
                                {
                                   this.salaire_store()
                                   this.$bvModal.hide('comptModal')
                                }
                                else if(this.parametreSalaire.id)
                                {
                                   this.annulSalaire()
                                   this.$bvModal.hide('comptModal')
                                }
                                else
                                {
                                    this.flashMessage.show({
                                    status: 'info',
                                    title: 'Infos incorrecte réessayez !!',
                                    })
                                }

            }
            else
            {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })

            }
        },

        salaire_store(){
           this.isLoading=true;


             this.$http.post('/salaires',{
            salaire_base:this.itemsalaireinfos.salaire_base,
            prix_heur_supp:this.itemsalaireinfos.prix_heur_supp,
            taux_ancien:this.itemsalaireinfos.taux_ancien,
            taux_cnss:this.itemsalaireinfos.taux_cnss,
            taux_amo:this.itemsalaireinfos.taux_amo,
            taux_cimr:this.itemsalaireinfos.taux_cimr,
            frais_profes:this.itemsalaireinfos.frais_profes,
            taux_impos:this.itemsalaireinfos.taux_impos,
            deduction_impos:this.itemsalaireinfos.deduction_impos,
            change_fami:this.itemsalaireinfos.change_fami,


            par_heur_suppNmbr:this.parametreSalaire.par_heur_suppNmbr,
            indeminit_impos:this.parametreSalaire.indeminit_impos,
            indeminit_non_impos:this.parametreSalaire.indeminit_non_impos,
            retenus_prets:this.parametreSalaire.retenus_prets,
            salaire_net:this.parametreSalaire.salaire_net,
            mois:this.itemEmploForm.mois,
            description:this.parametreSalaire.description,
            user:this.user_name,
            rh_empl_id:this.itemEmploForm.employe_id,
            bank_compte_id:this.parametreSalaire.bank_compte_id
                })
                .then(response => {

                    this.isLoading=false;

                    //initialiser le truc:
                    this.parametreSalaire.id = response.data.data.id

                    this.is_valider = true

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        verifier_salaire(id_empl,lmois)
        {
                      this.isLoading=true;
                      this.$http.get('/verifierSalaire/'+id_empl+'/'+lmois)
                      .then((result) => {

                      this.isLoading=false;


                                       
                               if(result.data.sal !=null)
                                {

                                    //valider le truc
                                    this.is_valider = true
                                    

                                    this.parametreSalaire.id = result.data.sal.id
                                    this.itemsalaireinfos.salaire_base= result.data.sal.salaire_base
                                    this.itemsalaireinfos.prix_heur_supp= result.data.sal.prix_heur_supp
                                    this.itemsalaireinfos.taux_ancien= result.data.sal.taux_ancien
                                    this.itemsalaireinfos.taux_cnss= result.data.sal.taux_cnss
                                    this.itemsalaireinfos.taux_amo= result.data.sal.taux_amo
                                    this.itemsalaireinfos.taux_cimr= result.data.sal.taux_cimr
                                    this.itemsalaireinfos.frais_profes= result.data.sal.frais_profes
                                    this.itemsalaireinfos.taux_impos= result.data.sal.taux_impos
                                    this.itemsalaireinfos.deduction_impos= result.data.sal.deduction_impos
                                    this.itemsalaireinfos.change_fami= result.data.sal.change_fami


                                    this.parametreSalaire.par_heur_suppNmbr= result.data.sal.par_heur_suppNmbr
                                    this.parametreSalaire.indeminit_impos= result.data.sal.indeminit_impos
                                    this.parametreSalaire.indeminit_non_impos= result.data.sal.indeminit_non_impos
                                    this.parametreSalaire.retenus_prets= result.data.sal.retenus_prets

                                    //calculer le salaire:
                                    this.calculer_click()

                                }
                                else
                                {
                                                    //valider le truc
                                                    this.is_valider = false

                                                    //cacher la partie :
                                                    this.parametreSalaire.id =''

                                                    //part
                                                    this.fetchSalaireInfos(id_empl)
                                } 


                      })
                      .catch(error=> {



                                  console.log(error)
                                  this.isLoading=false;
                                  this.parametreSalaire.id =''
                      });
        },
        click_annulSalaire() {

                this.$confirm("Voulez-vous vraiment Annuler la validation ??")
                 .then(() => {


                    //marker le type de select compt:
                    this.isAnnulSalaire= true
                    this.chargr_affich_compt();

                    })
                                .catch(error=> {
                                console.log(error)

                    });




        },
        annulSalaire()
        {

            this.isLoading=true;

            this.$http.get('/annulerSalaire/'+this.parametreSalaire.id+'/'+this.parametreSalaire.bank_compte_id+'/'+this.user_name)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                      this.is_valider=false
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });
        }, 

        getMoisName(mm)
        {
                        if (mm==1)
                        {
                        this.itemEmploForm.mois_nom='Janvier'
                        }
                        else if (mm==2)
                        {
                        this.itemEmploForm.mois_nom='Février'
                        }
                        else if (mm==3)
                        {
                        this.itemEmploForm.mois_nom='Mars'
                        }
                        else if (mm==4)
                        {
                        this.itemEmploForm.mois_nom='Avril'
                        }
                        else if (mm==5)
                        {
                        this.itemEmploForm.mois_nom='Mai'
                        }
                        else if (mm==6)
                        {
                        this.itemEmploForm.mois_nom='Juin'
                        }
                        else if (mm==7)
                        {
                        this.itemEmploForm.mois_nom='Juillet'
                        }
                        else if (mm==8)
                        {
                        this.itemEmploForm.mois_nom='Août'
                        }
                        else if (mm==9)
                        {
                        this.itemEmploForm.mois_nom='Septembre'
                        }
                        else if (mm==10)
                        {
                        this.itemEmploForm.mois_nom='Octobre'
                        }
                        else if (mm==11)
                        {
                        this.itemEmploForm.mois_nom='Novembre'
                        }
                        else if (mm==12)
                        {
                        this.itemEmploForm.mois_nom='Décembre'
                        }
                        else
                        {
                        this.itemEmploForm.mois_nom='--';
                        }

        },

        getHeuresTravail: function (emp_id,mois,vend_id) {
          this.isLoading = true;

                    this.$http.get('/pointMois/'+emp_id+'/'+mois+'/'+vend_id)
                    .then((result) => {

                                this.isLoading=false;


                                this.itemHeurMin.heurs= result.data.heur
                                this.itemHeurMin.mins= result.data.min
                                this.itemHeurMin.absencs= result.data.absencs
                                this.itemHeurMin.chiffr= result.data.chiffr
                                this.itemHeurMin.cred= result.data.cred

                    })
                    .catch(err => {
                       this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                            })
                                
                                console.log(err.message);
                    });

        },



        },
components: {
              Loading      
}
}
</script>

<style>
.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}
</style>