<template>
  
<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">
        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-address-book"></span> Absences Des Employés</h4> 
            
        </div>

<div class="row h-100 m-2">
  
        <div class="col-4">

               <div class="card border-info h-100 overflow-auto">


        <form @submit.prevent="SubmitFtchClient()" class="bg-info">

                <div class="input-group  border border-info rounded bg-info p-1 col">
                                              <div class="input-group-prepend">
                                                <span class="input-group-text">Départ.. </span>
                                              </div>
                                            
                                            <select  class="form-control" id="rh_departement_id" v-model="itemEmploForm.rh_departement_id">
                                            <option v-for="item in departements" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>

                                            </select>

                </div>


                <div class="input-group  border border-info rounded bg-info p-1 col">

                                              <div class="input-group-prepend">
                                                <span class="input-group-text">De </span>
                                              </div>
                                <b-form-datepicker
                                class="mr-sm-0"
                                id="datepicker-date_debut"
                                placeholder="date"
                                v-model="itemEmploForm.date_debut"
                                :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                locale="fr" />    
                </div>
                <div class="input-group  border border-info rounded bg-info p-1 col">
                    
                            
                                <div class="input-group-prepend">
                                <span class="input-group-text">Au</span>
                                </div>
                                <b-form-datepicker
                                class="mr-sm-0"
                                id="datepicker-date_fin"
                                placeholder="date"
                                v-model="itemEmploForm.date_fin"
                                :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                locale="fr" />    
                </div>


                <div class="border border-info rounded col p-1">
                        <button class="btn m-0 p-1 BgSearch btn-light btn-block border border-info rounded" type="submit" id="button-addon1">   
                          <span class="fa fa-search"></span> Liste
                        </button>
                </div>
                                                 

                                   
        </form>



                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchclient"  class="border-info " id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCINBarreSearch"
                                                v-model="isCINBarreSearch"
                                                name="isCINBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCINBarreSearch">Recherche Par Code</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                         
                            <b-list-group-item  v-for="item in filterEmployes" :key="item.id" button class="p-3" variant="info" @click="employe_click(item)">
                                 {{ item.nom_prenom }} -  {{ item.code }} 

                            </b-list-group-item>                          

                            </b-list-group>
               </div>
        </div> 


            <!-- LA PARTIE DES CARDES -->
        <div v-if="itemEmploForm.employe_id"  class="col-8  mr-auto ">


          <div class="row mb-3">
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-0">

                <div class="float-left pt-1 text-secondary">
                    <h5 class="font-weight-normal ml-2">{{itemEmploForm.employe_nom}} - <span class="d-inline font-weight-light">De : {{itemEmploForm.date_debut}} Au : {{itemEmploForm.date_fin}} </span></h5>
                </div>



                </div>
              </div>
            </div>

          </div>



        




                   <div class="row mt-3">
            <div class="col">
              <div class="card border-left-success shadow h-100">
                <div class="card-body p-0">

                 
                                <div class="rounded-lg shadow border border-white bg-white">
                                        <div class="row justify-content-centre align-items-center ">    

                                        
                                            <div class="col">
                                        <h5 class="font-weight-lighter ml-3"> <span class="text-success"> Justifiés : {{total_justifie}}</span> <span class="text-danger ml-5"> Non Justifié : {{total_nonjustifie}}</span>     </h5> 

                                            </div>

                                            <div class="col-3">
                                                            <b-button pill size="sm"  class="m-2 float-right" variant="success" @click="add_click($event.target)">
                                                                        <i class="fa fa-plus-square"></i>
                                                                <span> Ajouter</span> 
                                                            </b-button>
                                            </div>
                                        </div>
                                



                                    <b-table 
                                    responsive
                                    sort-icon-left
                                    head-variant="light"

                                    :fields="fields" 
                                    :items="itemslist"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    >
                                        <template #cell(actions)="row">
                                                <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                                            <i class="fa fa-trash-alt"></i>
                                                </b-button>
                                                <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                                                        <i class="fa fa-pencil-alt"></i>
                                                </b-button>
                                            </template>
                                    
                                    </b-table>


                                    <b-pagination
                                    pills
                                    v-model="currentPage"
                                    :total-rows="rows_pagin"
                                    :per-page="perPage"
                                    aria-controls="main-table"
                                    align="center"
                                    ></b-pagination>


                                </div>
                     


                </div>
              </div>
            </div>

          </div>
        
        </div>   


</div> 


    <!-- le modal -->
    <b-modal id="banqModal" title="Absence"  hide-footer>
           <form @submit.prevent="handleSubmit">



            <div class="row ">
                <div class="form-group col">
                    <label for="date" class="mr-sm-2">Date :</label>
                        <b-form-datepicker
                        class="mr-sm-2 form-control  form-control-sm"
                        id="datepicker-date"
                        placeholder="date"
                        v-model="itemForm.date"
                        :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                        locale="fr" />
                </div>

                <div class="form-group col">
                    <label for="nbr_jour">Nombre Jours</label>
                    <input type="text" v-model="itemForm.nbr_jour" id="nbr_jour" name="nbr_jour" class="form-control"/>


                </div>

            </div>


                        <div class="row ">
                <div class="form-group col">
                    <label for="date_debut" class="mr-sm-2">Date De Début :</label>
                        <b-form-datepicker
                        class="mr-sm-2 form-control  form-control-sm"
                        id="datepicker-date_debut"
                        placeholder="date"
                        v-model="itemForm.date_debut"
                        :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                        locale="fr" />
                </div>


                <div class="form-group col">
                    <label for="date_fin" class="mr-sm-2">Date De Fin :</label>
                        <b-form-datepicker
                        class="mr-sm-2 form-control  form-control-sm"
                        id="datepicker-date_fin"
                        placeholder="date"
                        v-model="itemForm.date_fin"
                        :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                        locale="fr" />
                </div>
            </div>





                        
                                <div class="custom-control custom-switch">
                                <input type="checkbox"
                                class="custom-control-input" 
                                id="abonne"
                                        v-model="itemForm.justif"
                                        name="abonne"
                                        value=true
                                        unchecked-value=false

                                >

                                <label class="custom-control-label" for="abonne">Justifié</label>
                                </div>


            <div class="form-group">
            <label for="Description">Motif</label>
            <textarea name="description" id="description"  rows="4" class="form-control" v-model="itemForm.motif"></textarea>

            </div>

            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>



</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";


export default {
      computed: {
      user_name() {
      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
       filterEmployes() {
       return this.employeList.filter(clnt => {

            if(this.isCINBarreSearch)
            {
                if(clnt.code)
                {
                return clnt.code.toLowerCase().includes(this.serchclient.toLowerCase())
                }

            }
            else
            {

                if(clnt.nom_prenom)
                {
                return clnt.nom_prenom.toLowerCase().includes(this.serchclient.toLowerCase())
                }


            }

      }) 
    },

      total_justifie: function() {  
          let res = this.itemslist.filter(it => it.justif ==1);     
    return res.reduce((a, b) => +a + +b.nbr_jour, 0);

    },


      total_nonjustifie: function() {  
          let res = this.itemslist.filter(it => it.justif ==0);     
    return res.reduce((a, b) => +a + +b.nbr_jour, 0);

    },
      rows_pagin() {
        return this.itemslist.length
      }
    },
    mounted(){
      this.fetchdepartements() 
    },
    data () {
		return {

            perPage: 10,
            currentPage: 1,

            departements:[],
            itemEmploForm: {
                        rh_departement_id:'',
                        date_debut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                        date_fin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                        employe_id:'',
                        employe_nom:''
            },
            employeList:[],

            itemForm: {
                    id:'',
                    date:'',
                    date_debut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    date_fin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    nbr_jour:0,
                    motif:'',
                    justif:false},
            fields: [
                {key: 'date', label: 'Date', sortable: true},
                {key: 'date_debut', label: 'D.Debut', sortable: true},
                {key: 'date_fin', label: 'D.Fin', sortable: true},
                {key: 'nbr_jour', label: 'Nombre Jours', sortable: true},
                {key: 'justif', label: 'Justifié', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'motif', label: 'Motif', sortable: true},
                { key: 'actions', label: '' }
            ],
            itemslist:[],
                        //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',

            isSubmitted: false,
            isLoading: false,
            isCINBarreSearch: false,
            serchclient:''
        }
        
        },
   validations: {
            itemForm: {
                date: {
                    required
                },
                date_debut: {
                    required
                },
                date_fin: {
                    required
                }         

            }

    },
        methods:{ 

        fetchdepartements: function () {

                    //chager les departements:
                    this.isLoading=true;
                    this.$http.get('/departements')
                    .then((result) => {
                                this.departements = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
                    },
        SubmitFtchClient(){

                if (this.itemEmploForm.rh_departement_id && this.itemEmploForm.date_debut && this.itemEmploForm.date_fin) {
                this.fetchListEmployes(this.itemEmploForm.rh_departement_id)
                }
                else 
                {

                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })


                }


        },
        fetchListEmployes(id_depart) {
                    this.isLoading=true;
                    this.$http.get('/empoyeListMin/'+id_depart)
                    .then((result) => {
                                this.employeList = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        employe_click(itm)
        {

            this.itemEmploForm.employe_id=itm.id

            this.itemEmploForm.employe_nom=itm.nom_prenom


            this.fetchListAbsence(itm.id)


        }, 

        //gestion des absences :


        itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/absences',{
            date:this.itemForm.date,
            date_debut:this.itemForm.date_debut,
            date_fin:this.itemForm.date_fin,
            nbr_jour:this.itemForm.nbr_jour,
            motif:this.itemForm.motif,
            justif:this.itemForm.justif,
            rh_empl_id:this.itemEmploForm.employe_id
                })
                .then(response => {

                    this.isLoading=false;

                    this.itemslist.unshift(response.data.data);

                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/absences/'+this.itemForm.id,{
            date:this.itemForm.date,
            date_debut:this.itemForm.date_debut,
            date_fin:this.itemForm.date_fin,
            nbr_jour:this.itemForm.nbr_jour,
            motif:this.itemForm.motif,
            justif:this.itemForm.justif,
            rh_empl_id:this.itemEmploForm.employe_id

                })
                .then(response => {

                    this.isLoading=false;

                    this.$bvModal.hide('banqModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/absences/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
            this.itemForm.id=''
            this.itemForm.date=new Date().toJSON().slice(0,10).replace(/-/g,'-')
            this.itemForm.date_debut=new Date().toJSON().slice(0,10).replace(/-/g,'-')
            this.itemForm.date_fin=new Date().toJSON().slice(0,10).replace(/-/g,'-')
            this.itemForm.nbr_jour=0
            this.itemForm.motif=''
            this.itemForm.justif=false


        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
        },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                this.itemForm.date=item.date
                this.itemForm.date_debut=item.date_debut
                this.itemForm.date_fin=item.date_fin
                this.itemForm.nbr_jour=item.nbr_jour
                this.itemForm.motif=item.motif
                this.itemForm.justif=item.justif

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


        },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                        this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        fetchListAbsence: function (id_emploi) {
                    this.isLoading=true;
                    this.$http.get('/absenceList/'+id_emploi+"/"+this.itemEmploForm.date_debut +"/"+ this.itemEmploForm.date_fin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            }

        },
components: {
              Loading      
}
}
</script>

<style>
.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}
</style>