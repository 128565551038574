<template>
<div>
  
               

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center ">  

            <div class="col">
            <h4 class=" font-weight-lighter text-info m-3 float-left"> <span class="fa fa-user-tie"></span>  Employés</h4> 

                <b-button pill size="sm"  class="mt-3 mb-3 float-left" variant="outline-success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>

            </div>
            
            <div class="col">
                <div class="input-group col">
                                    <select class="form-control border border-ligth " id="selected_depart" v-model="selected_depart">
                                            <option v-for="item in departements" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>
                                            <option value=0>Tous</option>

                                     </select>


                                    <div class="input-group-prepend">
                                        <button @click="click_AgriList" class="btn  btn-light  border border-ligth rounded-right " type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                </div>
            </div>
                                
              <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" class="col ml-4 mr-4"></b-form-input>




            </div>



        <b-table 
        responsive 
        id="main-table"

        sort-icon-left

        head-variant="light"
        :filter="filter"
        :fields="fields" 
        :items="agris"
        :per-page="perPage"
        :current-page="currentPage"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 mb-1  float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>

                    <b-button v-if="d_gst_trait_salaire==true" pill size="sm" class="mr-2 mb-1 float-right" variant="outline-info" @click="salaire_click(row.item)">
                            <i class="fa fa-money-bill-alt"></i>
                    
                    </b-button>

                </template>
        
        </b-table>


                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="banqModal" title="Employé" size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">

            <div class="row">
            <div class="form-group col">
                <label for="code">Code :</label>
                <input type="text" v-model="emploiForm.code" id="code" name="code" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.emploiForm.code.$error }" />
                <div v-if="isSubmitted && !$v.emploiForm.code.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

                    <div class="form-group col">
                        <label for="nom_prenom">Nom Et Prénom :</label>
                        <input type="text" v-model="emploiForm.nom_prenom" id="nom_prenom" name="nom_prenom" class="form-control form-control-sm"
                        :class="{ 'is-invalid': isSubmitted && $v.emploiForm.nom_prenom.$error }" />
                        <div v-if="isSubmitted && !$v.emploiForm.nom_prenom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                    </div>
            </div>



            <div class="row ">
                <div class="form-group col">
                    <label for="date_naiss" class="mr-sm-2">Date De Naissance :</label>
                        <b-form-datepicker
                        class="mr-sm-2 form-control  form-control-sm"
                        id="datepicker-date_naiss"
                        placeholder="date"
                        v-model="emploiForm.date_naiss"
                        :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                        locale="fr" />
                </div>


                <div class="form-group col">
                    <label for="date_recrut" class="mr-sm-2">Date De Recrutement :</label>
                        <b-form-datepicker
                        class="mr-sm-2 form-control  form-control-sm"
                        id="datepicker-date_recrut"
                        placeholder="date"
                        v-model="emploiForm.date_recrut"
                        :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                        locale="fr" />
                </div>
            </div>


            <div class="row">

            <div class="form-group col">
                <label for="civilite">Civilité :</label>
                                                <select  class="form-control  form-control-sm" id="civilite" v-model="emploiForm.civilite"
                                                :class="{ 'is-invalid': isSubmitted && $v.emploiForm.civilite.$error }">

                                                <option value=1>Monsieur</option>
                                                <option value=2>Madame</option>
                                                <option value=3>Mademoiselle</option>
                                                </select>
                <div v-if="isSubmitted && !$v.emploiForm.civilite.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">
                <label for="situat_matri">Situation Matrimonial :</label>
                                                <select  class="form-control  form-control-sm" id="situat_matri" v-model="emploiForm.situat_matri"
                                                :class="{ 'is-invalid': isSubmitted && $v.emploiForm.situat_matri.$error }">

                                                <option value=1>Célibataire</option>
                                                <option value=2>Marie(e)</option>
                                                <option value=3>Veuf(ve)</option>
                                                <option value=3>Divorcé(e)</option>
                                                </select>
                <div v-if="isSubmitted && !$v.emploiForm.situat_matri.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>
            </div>


            <div class="row">
                        <div class="form-group col">
                            <label for="centre">Département :</label>
                            <select class="form-control form-control-sm" id="centre" v-model="emploiForm.rh_departement_id"
                            :class="{ 'is-invalid': isSubmitted && $v.emploiForm.rh_departement_id.$error }">
                            <option v-for="item in departements" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>
                <div v-if="isSubmitted && !$v.emploiForm.rh_departement_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                        </div>
                        <div class="form-group col">
                            <label for="nbr_jour">Jours de congé annuel :</label>
                            <input type="text" v-model="emploiForm.nbr_jour" id="nbr_jour" name="nbr_jour" class="form-control form-control-sm"
                                :class="{ 'is-invalid': isSubmitted && $v.emploiForm.nbr_jour.$error }" />
                            <div v-if="isSubmitted && !$v.emploiForm.nbr_jour.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                            <div v-if="isSubmitted && !$v.emploiForm.nbr_jour.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                        </div>

            </div>

            <div class="row">
                        <div class="form-group col">
                            <label for="banque">Banque :</label>
                            <select class="form-control form-control-sm" id="banque" v-model="emploiForm.banque_id"
                            :class="{ 'is-invalid': isSubmitted && $v.emploiForm.banque_id.$error }">
                            <option v-for="item in banqs" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>
                        </div>

                        <div class="form-group col">
                            <label for="rib">RIB :</label>
                            <input type="text" v-model="emploiForm.rib" id="rib" name="rib" class="form-control form-control-sm" />

                        </div>
            </div>







            <div class="row">
                        <div class="form-group col">

                                        <label for="vendeur_id">Vendeur :</label>
                                        <select  class="form-control form-control-sm" id="vendeur_id" v-model="emploiForm.vendeur_id"
                                        :class="{ 'is-invalid': isSubmitted && $v.emploiForm.vendeur_id.$error }">
                                        <option v-for="item in vendeurs" :key="item.id" v-bind:value="item.id">   
                                            {{ item.nom_prenom }} - {{ item.code }} 
                                        </option>
                                        <option value=0>Aucun</option>

                                        </select>

                                        <div v-if="isSubmitted && !$v.emploiForm.vendeur_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                        </div>


                        <div  v-if="d_gst_trait_salaire==true" class="form-group col">
                            <label for="pass">PIN Pointeuse :</label>
                            <input type="text"  v-model="emploiForm.pass" id="pass" name="pass" class="form-control form-control-sm" />

                        </div>

            </div>

            <div class="row">

                <div class="col">
                                        <div class="custom-control custom-switch">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="etat"
                                                v-model="emploiForm.etat"
                                                name="etat"
                                                value=true
                                                unchecked-value=false

                                        >

                                        <label class="custom-control-label" for="etat">Actif</label>
                                        </div>
                </div>



                                    <div class="form-group col">
                                        <label for="tel">Téléphone :</label>
                                        <input type="text" v-model="emploiForm.tel" id="tel" name="tel" class="form-control form-control-sm" />

                                    </div>
            </div>
 

            
            <div class="form-group">
            <label for="adresse">Adresse :</label>
            <textarea name="adresse" id="adresse"  rows="2" class="form-control form-control-sm" v-model="emploiForm.adresse"></textarea>
            </div>
                            <div class="form-group">
                                <button type="submit" class="btn mt-3 btn-primary btn-block">{{ModalType}}</button>
                            </div>

        </form>
    </b-modal>







        <compSalair  v-bind:salaireInfosR="salaire_infoslocal" ></compSalair>

    

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import compSalair from '@/components/RH/SalaireInfos.vue';

export default {

    mounted(){


          this.fetchFormBanqs()
    },

    computed: {
      rows_pagin() {
        return this.agris.length
      },
          d_gst_trait_salaire() {
      return this.$store.getters.get_gst_trait_salaire;
    },
    },
	data () {
		return {
            salaire_infoslocal:'',
            selected_depart:'',
            filter: null,
            perPage: 10,
            currentPage: 1,
            vendeurs:[],
            banqs:[],
            departements:[],
            agris: [],
            emploiForm: {
                    id:'',
                    code:'',
                    nom_prenom:'',
                    date_naiss:'',
                    civilite:'',
                    situat_matri:'',
                    date_recrut:'',
                    nbr_jour:'',
                    etat:'',
                    tel:'',
                    adresse:'',
                    rib:'',
                    pass:'',
                    rh_departement_id:'',
                    banque_id:'',
                    vendeur_id:0
                    },
            fields: [

                {key: 'code', label: 'Code / CIN', sortable: true},
                {key: 'nom_prenom', label: 'Nom & Prénom', sortable: true},
                {key: 'departement', label: 'Département', sortable: true},
                {key: 'nbr_jour', label: 'J.Congé', sortable: true},
                {key: 'civilite', label: 'Civilité', 
                formatter: (value) => {
                        if(value==1)
                        {
                            return 'Monsieur'
                        }
                        else if(value==2)
                        {
                            return 'Madame'
                        }
                        else if(value==3)
                        {
                            return 'Mademoiselle'
                        }
                }, sortable: true},
                {key: 'situat_matri', label: 'Situation', 
                formatter: (value) => {
                        if(value==1)
                        {
                            return 'Célibataire'
                        }
                        else if(value==2)
                        {
                            return 'Marie(e)'
                        }
                        else if(value==3)
                        {
                            return 'Veuf(ve)'
                        }
                        else if(value==4)
                        {
                            return 'Divorcé(e)'
                        }
                }, sortable: true},
                {key: 'etat', label: 'Actif(ve)', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'tel', label: 'Tél', sortable: true},

                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            emploiForm: {
                code: {
                    required
                },
                nom_prenom: {
                    required
                },
                date_naiss: {
                    required
                },
                civilite: {
                    required
                },
                situat_matri: {
                    required
                },
                date_recrut: {
                    required
                },
                nbr_jour: {
                    required,
                    decimal
                }, 
                etat: {
                    required
                },
                banque_id: {
                    required
                },
                rh_departement_id: {
                    required
                },
                vendeur_id: {
                    required
                },
            }
    },
    methods :{
        salaire_click(item) {

                                this.$confirm("Voulez-vous modifier les information de salaire ??")
                                .then(() => {
                   // this.stockPars.article_id=item.id

                    this.fetchSalaireInfos(item.id)





                                })
                                .catch(error=> {
                                console.log(error)

                                    });
      },



        fetchSalaireInfos: function (emp_id) {
          this.isLoading = true;

                    this.$http.get('/getSalaireInfos/'+emp_id)
                    .then((result) => {
                               // this.Lotslist = result.data.data




                                this.isLoading=false;


                                //console.log(result.data.sal);

                                if(result.data.sal !=null)
                                {
                                    this.salaire_infoslocal = result.data.sal

                                    
                                    this.$root.$emit('bv::show::modal', 'lolmodal' )
                                }
                                else
                                {
                                                    this.flashMessage.show({
                                                    status: 'info',
                                                    title: 'Impossible de trouver les infos de salaire !!',
                                                    })
                                }
                               

                               // 
                    })
                    .catch(err => {
                       this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                            })
                
          console.log(err.message);
        });

        },



        banquestore(){
           this.isLoading=true;


             this.$http.post('/employes',{
            code:this.emploiForm.code,
            nom_prenom:this.emploiForm.nom_prenom,
            date_naiss:this.emploiForm.date_naiss,
            
            civilite:this.emploiForm.civilite,
            situat_matri:this.emploiForm.situat_matri,
            date_recrut:this.emploiForm.date_recrut,
            nbr_jour:this.emploiForm.nbr_jour,
            tel:this.emploiForm.tel,
            adresse:this.emploiForm.adresse,
            rib:this.emploiForm.rib,
            etat:this.emploiForm.etat,
            pass:this.emploiForm.pass,
            vendeur_id:this.emploiForm.vendeur_id,
            banque_id:this.emploiForm.banque_id,
            rh_departement_id:this.emploiForm.rh_departement_id
                })
                .then(response => {

                    this.isLoading=false;
                    this.agris.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/employes/'+this.emploiForm.id,{
            code:this.emploiForm.code,
            nom_prenom:this.emploiForm.nom_prenom,
            date_naiss:this.emploiForm.date_naiss,
            tel:this.emploiForm.tel,
            civilite:this.emploiForm.civilite,
            situat_matri:this.emploiForm.situat_matri,
            date_recrut:this.emploiForm.date_recrut,
            nbr_jour:this.emploiForm.nbr_jour,
            adresse:this.emploiForm.adresse,
            rib:this.emploiForm.rib,
            etat:this.emploiForm.etat,
            pass:this.emploiForm.pass,
            vendeur_id:this.emploiForm.vendeur_id,
            banque_id:this.emploiForm.banque_id,
            rh_departement_id:this.emploiForm.rh_departement_id

                })
                .then(response => {

                    this.isLoading=false;
                    this.$bvModal.hide('banqModal')

                    let index = this.agris.findIndex(item => item.id === this.emploiForm.id)
                    this.agris.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/employes/'+this.emploiForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.agris.findIndex(item => item.id === this.emploiForm.id)
                    this.agris.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.emploiForm.id='',
        this.emploiForm.code='--',

        this.emploiForm.nom_prenom='',
        this.emploiForm.date_naiss=new Date().toJSON().slice(0,10).replace(/-/g,'-'),


        this.emploiForm.tel='',
        this.emploiForm.civilite='',
        this.emploiForm.situat_matri='',
        this.emploiForm.date_recrut=new Date().toJSON().slice(0,10).replace(/-/g,'-'),

        this.emploiForm.adresse='',
        this.emploiForm.rib='',
        this.emploiForm.etat=true,
        this.emploiForm.pass='',
        this.emploiForm.nbr_jour=0,
        this.emploiForm.banque_id='',
        this.emploiForm.vendeur_id=0,
        this.emploiForm.rh_departement_id=this.selected_depart




        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
        },
        edit_click(item, index, button) {

                    this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                    .then(() => {


                        this.emploiForm.id=item.id,
                        this.emploiForm.code=item.code,

                        this.emploiForm.nom_prenom=item.nom_prenom,
                        this.emploiForm.date_naiss= item.date_naiss,

                        this.emploiForm.tel=item.tel,
                        this.emploiForm.civilite=item.civilite,
                        this.emploiForm.situat_matri=item.situat_matri,
                        this.emploiForm.date_recrut= item.date_recrut,

                        this.emploiForm.adresse=item.adresse,
                        this.emploiForm.rib=item.rib,
                        this.emploiForm.etat=item.etat,
                        this.emploiForm.pass=item.pass,
                        this.emploiForm.nbr_jour= item.nbr_jour,
                        this.emploiForm.vendeur_id=item.vendeur_id,
                        this.emploiForm.banque_id=item.banque_id,
                        this.emploiForm.rh_departement_id=item.rh_departement_id


                    this.ModalEdit=true;
                    this.ModalType='Modifier'

                    this.$root.$emit('bv::show::modal', 'banqModal', button)
                    })
                    .catch(error=> {
                    console.log(error)

                    });


        },
        del_click(item) {

                    this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                    .then(() => {
                    this.emploiForm.id=item.id,

                    this.banqueDell()
                                    })
                                    .catch(error=> {
                                    console.log(error)

                                        });

        },
        click_AgriList: function () {
                        if(this.selected_depart)
                        {
                                            this.isLoading=true;
                                            this.$http.get('/empoyeList/'+this.selected_depart)
                                            .then((result) => {
                                                        this.agris = result.data.data
                                                        this.isLoading=false;

                                                    
                                            })
                                            .catch(error=> {
                                                        console.log(error)
                                                        this.isLoading=false;
                                                        this.flashMessage.show({
                                                        status: 'error',
                                                        title: 'Erreur de Chargement',
                                                        message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                                        })
                                            });
                        }
                        else
                        {
                                        this.flashMessage.show({
                                        status: 'info',
                                        title: 'Sélectionez un département et Reésayez !!',
                                        })
                        }
        },    
        fetchFormBanqs: function () {
                    this.isLoading=true;
                    this.$http.get('/banques')
                    .then((result) => {
                                this.banqs = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les departements:
                    this.isLoading=true;
                    this.$http.get('/departements')
                    .then((result) => {
                                this.departements = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible de charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les vendeurs:
                    this.isLoading=true;
                    this.$http.get('/vendeursMinActif')
                    .then((result) => {
                                this.vendeurs = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },  
        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.banquestore()
                            }   

                }

            }

    },
    components: {
              Loading,
              compSalair      
    }
}
</script>

<style  scoped>

</style>